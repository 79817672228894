import React, { useEffect } from "react";
import PC from "./pc";
import Mobile from "./mobile";

function isMobile() {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let getArr = Agents.filter((i) => userAgentInfo.includes(i));
  return getArr.length ? true : false;
}
function App() {
  const ismobile = isMobile();
  return ismobile ? <Mobile /> : <PC />;
}

export default App;
