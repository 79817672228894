import React from "react";
import "./index.scss";

const ProductItem = (props) => {
  const { position, data, icon } = props;
  const { title1, title2, title3, tag } = data;
  return (
    <div className="content-product">
      <div className="content-product-image">
        <img className="content-product-image-img" src={icon} alt="" />
      </div>
      <div className="content-product-introduce">
        <div className="content-product-box">
          <div className="title1">
            <span className="title2">{title1}</span>
            {tag}
          </div>
          <div className="title2">{title2}</div>
          <div className="title3">{title3}</div>
          <div className="split-lines"></div>
        </div>
      </div>
    </div>
  );
};
export default ProductItem;
