import React, { useEffect } from "react";
import { Layout, Modal, Menu } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon, { UnorderedListOutlined } from "@ant-design/icons";
import { EffectFade, Autoplay, Controller } from "swiper/modules";
import ProductIntroduce from "../components/product-introduce-mobile";
import Footer from "../components/footer-mobile";
import About from "../components/about-mobile";
import Img1 from "../assets/1.jpg";
import Img2 from "../assets/2.jpg";
import Img3 from "../assets/3.jpg";
import Img4 from "../assets/4.jpg";
import Banner1 from "../assets/banner5.jpg";
import Banner2 from "../assets/banner2.jpg";
import Banner3 from "../assets/banner3.jpg";
import Banner4 from "../assets/banner4.jpg";
import Logo from "../assets/kww.png";

import "./index.scss";
import "swiper/scss"; // core Swiper
import "swiper/scss/navigation"; // Navigation module
import "swiper/scss/pagination"; // Pagination module
import "swiper/scss/effect-fade";

const items = [
  {
    key: "sub2",
    label: "kww",
    icon: <UnorderedListOutlined />,
    children: [
      { key: "1", label: "酷汪汪壁纸" },
      { key: "2", label: "酷汪汪DIY" },
      { key: "3", label: "酷汪汪AI绘图" },
      { key: "4", label: "关于我们" },
      { key: "5", label: "联系我们" },
    ],
  },
];

const { Header, Content } = Layout;

const Mobile = () => {
  const onClickToWallpaper = () => {
    const qr = "https://kww-cdn-1.koowangwang.com/static/app-icon/mobile.png";
    Modal.info({
      title: "扫描二维码即可前往壁纸小程序",
      content: (
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={qr} alt={""} style={{ marginLeft: "58px" }} />
        </div>
      ),
      onOk() {},
    });
  };

  const onClickToDiy = () => {
    const qr = "https://kww-cdn-1.koowangwang.com/static/app-icon/wechat.png";
    Modal.info({
      title: "扫描二维码入群进行DIY设计",
      content: (
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={qr} alt={""} style={{ marginLeft: "48px" }} />
        </div>
      ),
      onOk() {},
    });
  };
  const onClickToAi = () => {
    const qr = "https://kww-cdn-1.koowangwang.com/static/app-icon/mobile.png";
    Modal.info({
      title: "扫描二维码即可前往AI绘图应用程序",
      content: (
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={qr} alt={""} style={{ marginLeft: "88px" }} />
        </div>
      ),
      onOk() {},
    });
  };

  const onClickToShowUs = () => {
    window.scrollTo(600, 0);
  };
  const onClickToShowCantactUs = () => {
    const qr = "https://kww-cdn-1.koowangwang.com/static/app-icon/qq.png";
    Modal.info({
      title: "扫描二维码进入交流群",
      content: (
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={qr} alt={""} style={{ marginLeft: "8px" }} />
        </div>
      ),
      onOk() {},
    });
  };

  const onClick = (e) => {
    console.log("click ", e);
    if (e.key === "1") {
      onClickToWallpaper();
    }
    if (e.key === "2") {
      onClickToDiy();
    }
    if (e.key === "3") {
      onClickToAi();
    }

    if (e.key === "4") {
      onClickToShowUs();
    }

    if (e.key === "5") {
      onClickToShowCantactUs();
    }
  };

  return (
    <div>
      <div className={"header"}>
        <Menu
          onClick={onClick}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          items={items}
        />
      </div>
      <Swiper
        modules={[EffectFade, Autoplay, Controller]}
        effect="fade"
        delay={1500}
        loop
        autoplay
        style={{ marginTop: "52px" }}
      >
        <SwiperSlide>
          <div className={"content-bar"}>
            <img src={Banner1} alt={""} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={"content-bar"}>
            <img src={Banner2} alt={""} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={"content-bar"}>
            <img src={Banner3} alt={""} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={"content-bar"}>
            <img src={Banner4} alt={""} />
          </div>
        </SwiperSlide>
      </Swiper>
      <About />
      <ProductIntroduce
        icon={Img3}
        data={{
          title1: "创意",
          title2: "实现你的灵感",
          title3: "AI创作为你的天马行空存在",
          tag: "绘画",
        }}
      />

      <ProductIntroduce
        icon={Img1}
        data={{
          title1: "自主",
          title2: "0成本手工制作",
          title3: "精美礼品，瞬时即达",
          tag: "DIY",
        }}
        position={"right"}
      />

      <ProductIntroduce
        icon={Img2}
        data={{
          title1: "唯美",
          title2: "畅游美的殿堂",
          title3: "汇聚创造力，灵感信手拈",
          tag: "壁纸",
        }}
      />
      <ProductIntroduce
        icon={Img4}
        data={{
          title1: "自主",
          title2: "0成本手工制作",
          title3: "精美礼品，瞬时即达",
          tag: "DIY",
        }}
        position={"right"}
      />

      <Footer />
    </div>
  );
};

export default Mobile;
