import React from "react";
import "./index.scss";

const FooterMobile = (props) => {
  const { position, data, icon } = props;
  const handleOpenWindow = () => {
    window.open("https://kww-cdn-1.koowangwang.com/license.png");
  };
  return (
    <>
      <div className="footer-content">
        <div>
          北京酷汪汪科技有限公司成立于2024年，致力于打造一家流行的潮流文化娱乐公司，公司主要围绕创意idea发掘、IP孵化运营、3d，AI赋能、潮流文化推广、相关产品制造这五大领域，
          构建一个覆盖潮流玩具全产业链的运营平台。公司理念: 探索创新、引流潮流
        </div>
        <div className="split-line"></div>
        <div className="footer-detail">
          <span>公司：北京酷汪汪科技有限公司</span>
          <div>
            <span>联系电话:18010617237</span>
            <span>北京市大兴区黄村镇兴盛街124号1至2层124</span>
          </div>
          <div>
            <span>COPYRIGHT © 酷汪汪 COOLWW.COM ALL RIGHTS RESERVED</span>
            <span>京ICP备 2024087048号</span>
            <span
              style={{ cursor: "pointer", color: "#fde5b8" }}
              onClick={handleOpenWindow}
            >
              营业执照
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default FooterMobile;
