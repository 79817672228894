import React from "react";
import "./index.scss";
import FashionImg from "../../assets/fashion.png";
import Icon1 from "../../assets/icon1.png";
import Icon2 from "../../assets/icon2.png";
import Icon3 from "../../assets/icon3.png";
import Icon4 from "../../assets/icon4.png";
import LeftImg from "../../assets/left.png";
import RightImg from "../../assets/right.png";

const About = (props) => {
  const { position, data, icon } = props;

  return (
    <>
      <div id="aboutus" className="content-about">
        <div className="about-title">关于我们</div>
        <div className="about-content">
          北京酷汪汪科技有限公司成立于2024年，致力于打造一家流行的潮流文化娱乐公司
        </div>
        <div className="about-content">
          公司主要围绕创意idea发掘、IP孵化运营、3D、AI赋能、潮流文化推广、相关产品制造这五大领域
        </div>
        <div className="about-content">
          构建一个覆盖潮流玩具全产业链的运营平台
        </div>
        <div className="about-content"> 公司理念: 探索创新、引流潮流</div>

        <div className="about-icon"></div>
      </div>
      <div className="content-tag">
        <div className="tag-title">创造潮流 & 传递美好</div>
        <div className="tag-item">
          <div>
            <img src={Icon1} alt="" />
            <span>DIY设计</span>
            <div>
              利用AI+大模型赋能，设计个性类创意Idea,推出受众喜爱类潮流产品
            </div>
          </div>
          <div>
            <img src={Icon2} alt="" />
            <span>潮流Style</span>
            <div>
              创造性的将绘画、积木、浮雕、手办等诸多元素，融入进推行产品之中
            </div>
          </div>
        </div>
        <div className="tag-item">
          <div>
            <img src={Icon3} alt="" />
            <span>产权保护</span>
            <div>
              保护每位设计者的创作产品，相互尊重并打造时下最受消费者喜爱的文创爆品
            </div>
          </div>{" "}
          <div>
            <img src={Icon4} alt="" />
            <span>潮流汇集</span>
            <div>
              更多设计者在平台上实现个人的概念创想，联名打造一流的潮流文化聚集圈
            </div>
          </div>
        </div>
        <img src={LeftImg} className="tag-left" alt="" />
        <img src={RightImg} className="tag-right" alt="" />
      </div>
    </>
  );
};
export default About;
