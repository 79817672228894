import React from "react";
import "./index.scss";

const f = (props) => {
  const { position, data, icon } = props;
  const handleOpenWindow = () => {
    window.open("https://kww-cdn-1.koowangwang.com/license.png");
  };
  return (
    <>
      <div className="f-content">
        <div className="f-txt">
          北京酷汪汪科技有限公司成立于2024年，致力于打造一家流行的潮流文化娱乐公司
        </div>
        <div className="f-txt">
          公司主要围绕创意idea发掘、IP孵化运营、3d，AI赋能、潮流文化推广、相关产品制造这五大领域
        </div>
        <div className="f-txt">
          构建一个覆盖潮流玩具全产业链的运营平台。公司理念: 探索创新、引流潮流
        </div>
        <div className="f-split-line"></div>
        <div className="f-detail">
          <div>公司：北京酷汪汪科技有限公司</div>
          <div>联系电话:18010617237</div>
          <div>北京市大兴区黄村镇兴盛街124号1至2层124</div>
          <div>COPYRIGHT © 酷汪汪</div>
          <div> COOLWW.COM ALL RIGHTS RESERVED</div>
          <div>
            <span>京ICP备 2024087048号</span>
            <span
              style={{ cursor: "pointer", color: "#fde5b8" }}
              onClick={handleOpenWindow}
            >
              营业执照
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default f;
